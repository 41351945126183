<template>
  <b-col class="p-0" lg="6">
    <b-card class="border" body-class="p-1">
      <app-overlay>
        <app-detail-table :fields="fields" :data="companyData" />
      </app-overlay>
    </b-card>
  </b-col>
</template>

<script>
export default {
  data: () => ({
    companyData: {},
    fields: [
      { key: "contractNumber", label: "Sözleşme No", icon: "FileTextIcon" },
      {
        key: "contractDate",
        label: "Sözleşme Tarihi",
        icon: "CalendarIcon",
        formatDate: true,
      },
      { key: "contractPrice", label: "Sözleşme Ücreti", icon: "FileTextIcon" },
    ],
  }),
  computed: {
    company() {
      return this.$store.getters?.selectedCompany?._id;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.company) {
      }
    },
  },
};
</script>

<style></style>
