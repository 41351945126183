<template>
  <b-col class="p-0" lg="6">
    <b-card class="border" body-class="p-50">
      <app-overlay>
        <app-detail-table :fields="firstField" :data="companyData">
          <template #name="{ name }">
            {{ $capitalize(name) }}
          </template>
        </app-detail-table>
      </app-overlay>
    </b-card>
  </b-col>
</template>

<script>
export default {
  data: () => ({
    firstField: [
      { key: "name", label: "Firma Unvanı", icon: "BriefcaseIcon" },
      { key: "naceCode", label: "Nace Kodu", icon: "FileIcon" },
      {
        key: "sector.value",
        label: "Sektör",
        icon: "BriefcaseIcon",
        capitalize: true,
      },
      { key: "taxNo", label: "Vergi Numarası", icon: "FileIcon" },
      { key: "employeeCount", label: "Toplam Çalışanlar", icon: "UsersIcon" },
      { key: "contractNumber", label: "Sözleşme No", icon: "FileTextIcon" },
      {
        key: "contractDate",
        label: "Sözleşme Tarihi",
        icon: "CalendarIcon",
        formatDate: true,
      },
      { key: "contractPrice", label: "Sözleşme Ücreti", icon: "FileTextIcon" },
    ],
    companyData: {},
  }),
  computed: {
    company() {
      return this.$store.getters?.selectedCompany?._id;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.company) {
        this.$axios
          .get(`/company/${this.company}`, { params: { populates: JSON.stringify(["sector", "employeeCount"]) }, loading: "table" })
          .then((result) => {
            this.companyData = result.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style></style>
