<template>
  <app-overlay>
    <!-- @row-clicked="showSidebar" -->
    <db-searchable-table class="border m-0" v-model="filters" @changed="getData" :items="branches" :fields="fields" :total-rows="total" hover searchable>
      <template #modalbutton>
        <!--  @click="$showAppSidebar('Sicil Ekle', branchSidebar)" -->
        <app-button text="Sicil Ekle" icon="PlusIcon" size="md" />
      </template>
      <template #confirmation="{ item }">
        <b-badge v-if="item.confirmation" :variant="item.confirmation.status === 'beklemede' ? 'warning' : item.confirmation.status === 'reddedildi' ? 'danger' : 'success'">
          {{ $capitalize(item.confirmation.status) }}
        </b-badge>
        <span v-else> - </span>
      </template>
      <template #actions>
        <app-icon-button variant="gradient-danger" icon="TrashIcon" />
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import branchSidebar from "./sidebar.vue";

export default {
  data() {
    return {
      filters: {},
      fields: [
        { key: "name", label: "Sicil Adı" },
        { key: "recordNo", label: "Sicil No" },
        { key: "riskState.value", label: "Tehlike Sınıfı" },
        { key: "wsExpertAssignment.physician.auth.fullname", label: "ISG Uzmanı" },
        { key: "physicianAssignment.physician.auth.fullname", label: "İşyeri Hekimi" },
        { key: "confirmation", label: "Onay Durumu" },
        { key: "actions", label: "Eylemler" },
      ],
      branchSidebar,
    };
  },
  computed: {
    company() {
      return this.$store.getters?.selectedCompany?._id;
    },
    branches() {
      return this.$store.getters.branches?.data ?? [];
    },
    total() {
      return this.$store.getters.branches?.total ?? 0;
    },
  },

  methods: {
    showSidebar(item) {
      this.$axios
        .get(`/company/branches/${item._id}`, { loading: "table" })
        .then((result) => {
          this.$showAppSidebar("Sicil Düzenle", branchSidebar, result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      if (this.company) {
        this.$axios
          .get("/company/branches/list/paginated", {
            params: { ...this.query, company: this.company, populates: JSON.stringify(["riskState", "wsExpertAssignment", "physicianAssignment"]) },
            loading: "table",
          })
          .then((result) => {
            this.$store.commit("setBranches", result.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  destroyed() {
    this.$store.commit("setBranches", { data: [], total: 0 });
  },
};
</script>

<style></style>
