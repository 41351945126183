<template>
  <app-overlay>
    <db-searchable-table v-model="filters" @changed="getFiles" class="border" :items="items" :total-rows="total" :fields="tableFields" searchable>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="downloadFile(item._id)">
            <feather-icon icon="DownloadIcon" class="mr-50" />
            İndir
          </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data: () => ({
    filters: {},
    items: [],
    total: 0,
    tableFields: [
      { key: "customName", label: "Dosya Adı" },
      { key: "fileType", label: "Dosya Tipi" },
      { key: "createdAt", label: "Yükleme Tarihi", formatDateTime: true },
      { key: "actions", label: "İşlemler" },
    ],
  }),
  computed: {
    company() {
      return this.$store.getters?.selectedCompany?._id;
    },
  },
  methods: {
    getFiles() {
      this.company &&
        this.$axios
          .get("/company-file", {
            params: { ...this.filters, company: this.company },
            loading: "table",
          })
          .then((res) => {
            this.items = res.data.data;
            this.total = res.data.total;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    downloadFile(id) {
      this.$axios
        .get(`/company-file/${id}`, { loading: "table", responseType: "blob" })
        .then((result) => {
          const blob = new Blob([result.data], {
            type: result.headers["content-type"],
          });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", {
            variant: "danger",
            title: "Hata Oluştu",
            message: err?.data?.message ?? null,
          });
        });
    },
  },
};
</script>

<style></style>
