<template>
  <b-custom-tabs :tabs="tabs" lazy-once />
</template>

<script>
import documents from "./documents.vue";
import CompanyInfo from "./CompanyInfo.vue";
import branches from "./branches/list.vue";
import contractInfo from "./contractInfo.vue";

export default {
  data() {
    return {
      tabs: [{ title: "Genel Bilgiler", component: CompanyInfo }, { title: "Belgeler", component: documents }, { title: "Siciller", component: branches }, { title: "Atamalar" }],
    };
  },
};
</script>

<style></style>
