<template>
  <GmapMap
    v-show="location.lat && location.lng"
    id="map"
    ref="mapRef"
    :options="{
      zoomControl: true,
      fullscreenControl: true,
      clickableIcons: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      disableDefaultUi: false,
    }"
    :center="mapOptions.center"
    :zoom="mapOptions.zoom"
    style="width: 100%; height: 30vh"
    @click="selectedLocation"
  >
    <GmapMarker
      v-if="location.lat && location.lng"
      :position="location"
    />
  </GmapMap>
</template>

<script>
export default {
  data: () => ({
    location: { lat: null, lng: null },
  }),
  computed: {
    mapOptions() {
      return {
        center: { lat: 38.963745, lng: 35.243322 },
        zoom: 6.3,
      }
    },
  },
  methods: {
    selectedLocation(event) {
      const location = { lat: event.latLng.lat(), lng: event.latLng.lng() }
      this.setLocation(location)
      this.$emit('location-selected', location)
    },
    setLocation(loc) {
      this.$nextTick(() => {
        this.$refs?.mapRef?.$mapPromise.then(map => map.panTo(loc))
        this.location = loc
        this.mapOptions.zoom = 16
      })
    },
  },
}
</script>

<style></style>
