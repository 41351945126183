<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="branchform">
      <app-input v-model="data.name" name="Sicil Adı" label="Sicil Adı:" placeholder="Sicil Adı..." rules="required" />
      <app-input v-model="data.recordNo" name="İşyeri Sicil Numarası" type="number" label="İşyeri Sicil Numarası:" placeholder="İşyeri Sicil Numarası..." rules="required" />
      <riskstate-select v-model="data.riskState" required />
      <!-- Adres -->
      <provinces v-model="data.address.province" @selected="cleanOsb" />
      <counties v-model="data.address.county" :province="data.address.province" />
      <districts v-model="data.address.district" :county="data.address.county" @selected="selectedDistrict" />
      <b-row>
        <b-col cols="7">
          <app-input v-model="data.address.street" name="Sokak" label="Sokak:" placeholder="Sokak..." />
        </b-col>
        <b-col cols="5">
          <app-input v-model="data.address.buildingNo" name="Numara" label="Numara:" placeholder="Numara..." />
        </b-col>
      </b-row>
      <b-form-checkbox v-model="osbSelected" class="custom-control-danger mb-50" @change="getOsbOptions"> OSB - KSS </b-form-checkbox>
      <app-select-input
        v-if="osbSelected"
        v-model="data.address.osb"
        :reduce="(option) => option._id"
        name="OSB"
        placeholder="Seçim yapınız..."
        select_label="name"
        :options="osbOptions"
      />
      <hr />
      <b-row>
        <b-col cols="6">
          <app-button block text="Haritada Göster" icon="MapPinIcon" @click="setFullAddress" />
        </b-col>
        <b-col cols="6">
          <app-button block :text="updateItem ? 'Güncelle' : 'Kaydet'" icon="" @click="updateItem ? updateBranch() : newBranch()" />
        </b-col>
      </b-row>
      <!-- Harita -->
      <div v-show="data.address.district" ref="googleMap">
        <hr />
        <map-vue ref="mapApi" @location-selected="selectedLocation" />
      </div>
    </validation-observer>
  </app-overlay>
</template>

<script>
import graphqlRequest from "@/utils/graphqlRequest";
import { ValidationObserver } from "vee-validate";
import mapVue from "./map.vue";

export default {
  components: { ValidationObserver, mapVue },
  data() {
    return {
      osbOptions: [],
      osbSelected: false,
      data: {
        address: {},
      },
      flag: false,
    };
  },
  computed: {
    company() {
      return this.$store.getters?.selectedCompany?._id;
    },
    updateItem() {
      return this.$store.getters.appSidebarItem;
    },
  },
  watch: {
    "data.address.province": function () {
      this.getOsbOptions();
    },
  },
  mounted() {
    if (this.updateItem) {
      this.data = this.updateItem;
      this?.data?.address?.location?.lat &&
        this?.data?.address?.location?.lng &&
        this.$nextTick(() => {
          this.$refs.mapApi.setLocation(this.data.address.location);
        });
      if (this?.data?.address?.osb && this?.data?.address?.province) {
        this.osbSelected = true;
      }
    }
  },
  methods: {
    setData() {
      return { ...this.data, company: this.company };
    },
    newBranch() {
      this.$validate(this.$refs.branchform, () => {
        /*  this.$axios
          .post('/company/branches', this.setData(), { loading: 'sidebar' })
          .then(response => {
            this.$store.commit('setNewBranch', response.data)
            this.$store.commit('cleanAppSidebar')
          })
          .catch(error => {
            console.log(error)
            this.$emitter.$emit('Notification', { variant: 'danger', title: 'Hata Oluştu' })
          }) */
      });
    },
    updateBranch() {
      this.$validate(this.$refs.branchform, () => {
        console.log(this.setData());
      });
    },
    setLocation(address) {
      this.$axios
        .post("/location/get-coordinates", {
          address,
        })
        .then((response) => {
          this.$refs.mapApi.setLocation(response.data);
          this.data.address.location = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectedDistrict(val) {
      if (val) {
        const { province, county, district } = this.data.address;
        this.setLocation(`${district.value} ${county.value} ${province.value}`);
      }
    },
    setFullAddress() {
      const { province, county, district, buildingNo, street } = this.data.address;
      if (!Object.keys(this.data.address).some((key) => !this.data.address[key])) {
        this.setLocation(`${street} ` + `No:${buildingNo} ${district.value} ${county.value} ${province.value}`);
      }
    },
    selectedLocation(location) {
      this.location = location;
    },
    getOsbOptions() {
      this.osbOptions = [];
      this.flag ? (this.data.address.osb = null) : (this.flag = true);
      if (this.data.address?.province && this.osbSelected) {
        graphqlRequest("/optionData", {
          query: `
          {
              OsbList(province: "${this?.data?.address?.province._id}"){ _id name }
          }`,
        })
          .then(({ OsbList }) => {
            this.osbOptions = OsbList;
          })
          .catch((error) => {});
      }
    },
    cleanOsb(province) {
      console.log(province, this.data?.address?.osb);
      if (!province && this.data?.address?.osb) {
        this.data.address.osb = null;
      }
    },
  },
};
</script>
